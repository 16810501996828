import Vue from "vue";
import { Component } from 'vue-property-decorator'
import './landing.scss'
import { UserService } from 'service'
import { Routes } from 'route/routes'
import ApiService from './landing.api'
import NotifyGroup from 'component/notify'
import Spin from 'component/spin'
import Input from 'component/input/input'
import Button from 'component/button/button'
import Text from 'component/text/text'
import Gap from 'component/grid/gap'

@Component
export default class PageLanding extends Vue {
  private username: string = ''
  private password: string = ''

  private get elPassword(): HTMLInputElement {
    return this.$refs.password as HTMLInputElement
  }

  onUsernamePressed(e: KeyboardEvent) {
    if (e.which !== 13) return

    this.password !== ''
      ? this.login()
      : this.elPassword.focus()
  }

  onPasswordPressed(e: KeyboardEvent) {
    if (e.which === 13) this.login()
  }

  async login() {
    try {
      this.$spin.show('正在登录中...')

      const user = await UserService.login(this.username, this.password)

      window.location.assign(`${location.origin}/?user=${user._id}`)
    } catch (e) {
      this.$notify.error(e.message)
    } finally {
      this.$spin.hide()
    }
  }

  render(h) {
    return (
      <div class="pageLanding">

        <header>
          <div class="landing-title"></div>
        </header>

        <main>
          <div class="landing-main">
            <div class="landing-slogen"></div>

            <div class="landing-opBox">
              <div class="login-entry">
                <Text
                  class="login-title"
                  type="main-head"
                  center>用户登录</Text>
              </div>

              <div class="login-entry">
                <input
                  type="text"
                  ref="username"
                  class="login-input"
                  placeholder="登录账号"
                  value={this.username}
                  onInput={e => this.username = e.target.value}
                  onKeydown={e => this.onUsernamePressed(e)} />

                <div class="login-input-icon login-input-icon-username"></div>
              </div>

              <div class="login-entry">
                <input
                  type="password"
                  ref="password"
                  class="login-input"
                  placeholder="密码"
                  value={this.password}
                  onInput={e => this.password = e.target.value}
                  onKeydown={e => this.onPasswordPressed(e)} />

                <div class="login-input-icon login-input-icon-password"></div>
              </div>

              <Gap></Gap>

              <div class="login-entry">
                <Button
                  class="login-button"
                  expand
                  onClick={() => this.login()}>登录</Button>
              </div>
            </div>
          </div>
        </main>

        <footer>
          <div class="landing-copyright">
            <Text center>沪ICP备14021340号 | Copyright © 2013-2017 jfjun.com All Rights Reserved 灵兮科技 版权所有</Text>
            <Text center>上海灵兮信息科技有限公司  地址: 上海市 杨浦区 隆昌路619号2号楼A29-A31室</Text>
          </div>
        </footer>

        <NotifyGroup></NotifyGroup>
        <Spin></Spin>
      </div>
    )
  }
}