import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import './gap.scss'

@Component
export default class Gap extends Vue {
  render(h) {
    return (
      <div class="jfj-grid-gap"></div>
    )
  }
}